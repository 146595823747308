import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { class: "navbar-brand navbar-item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "progressbar-wrapper navbar-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_BasicButton, {
      variant: "link",
      onButtonClicked: _ctx.stepBack,
      "additional-button-classes": "navbar-back navbar-item color-text-color"
    }, {
      default: _withCtx(() => [
        _createTextVNode("Zurück")
      ]),
      _: 1
    }, 8, ["onButtonClicked"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.logoPath,
        alt: "SZ Plus"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.isInternalView)
        ? (_openBlock(), _createBlock(_component_ProgressBar, {
            key: 0,
            steps: 5
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}