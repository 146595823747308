import { ConfigActionTypes } from '@/store/modules/config/types';
import { SingleUserOrderBonus } from '@/types/OrderBonusesTypes';
import { PaymentData, priceInfo } from '@/models/OrderData';

enum RootActionTypes {
  SUBMIT_ORDER = 'submitOrder',
  UPDATE_ADMIN_LOGOUT_URL = 'updateAdminLogoutUrl',
  UPDATE_PRICE_WITH_DELIVERY_DATE = 'updatePriceWithDeliveryDate',
  UPDATE_START_DATE = 'updateStartDate',
}

export const AllActionTypes = { ...ConfigActionTypes, ...RootActionTypes };

export enum StoreModules {
  CONFIG_MODULE = 'configModule/',
  ADMIN_ORDER_BONUSES_MODULE = 'adminOrderBonusesModule/',
}

export type StoreFormData = {
  addressSubmittedSuccessfully: boolean;
  gpNr: string;
  gpLockedOrArchived: boolean;
  address: {
    addressNumber: string;
    city: string;
    country: string;
    email: string;
    firstName: string;
    lastName: string;
    nameEditable: boolean;
    salutation: string;
    title: string;
    street: string;
    streetNumber: string;
    postOfficeBox: string;
    postOfficeBoxZipCode: string;
    postOfficeBoxCity: string;
    zipCode: string;
    additionalInfo: string;
    floor: string;
    room: string;
    addressAddition: string;
    district: string;
    phone: string;
  };
  selectedOrderBonuses?: SingleUserOrderBonus[];
  isPostOfficeBoxAddress: boolean;
  orderWithoutAddress: boolean;
  registrationMandatory: boolean;
  existingSubscription: boolean;
  schnorrer: boolean;
  offerId: string;
  salesCampaign: {
    salesCampaignId: string;
    cancellationInfo: string;
    priceForTracking: number;
    priceNetForTracking: number;
    printProductType: string;
    infoPoints: string[];
    priceInfos: priceInfo[];
    name: string;
    recurring: boolean;
    withCosts: boolean;
    registrationMandatory: boolean;
    mailAdvertEnabled: boolean;
    telAdvertEnabled: boolean;
    emailMandatory: boolean;
    allowOrderDespiteExistingSubscription: boolean;
    bonuses?: SingleUserOrderBonus[];
    noResultAbroad: boolean;
    showStartDate: boolean;
    preventOrderWithExistingSubscription: boolean;
  };
  gotoCheckData?: boolean;
  paymentData?: PaymentData[];
  paymentMethodKeys: string[];
  regionSelectionOptions?: {
    [key: string]: string;
  };
  countries: {
    [key: string]: string;
  };
  trackingData: {
    categoryName: string;
  };
  email: string;
  sapConnectionFailed: boolean;
  startDate: string;
  mailAdvertEnabled: boolean;
  telAdvertEnabled: boolean;
};
