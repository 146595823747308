import store from '@/store';
import router from '@/router';
import {
  addressValidationResponseFromAPI,
  FormAddresses,
} from '@/models/OrderData';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';
import SetupService from '@/services/setup-service';
import { FormDataParsingResult } from '@/types/OrderFormTypes';

export default class FormService {
  public static unavailableProductMessages = [
    'NO_OFFER_STORE',
    'NOT_IN_PRODUCT_CATALOG',
    'INVALID_SALES_CAMPAIGN_ID',
    'NO_DATA_FOR_OFFER_ID',
    'NO_OFFER_STORE_FOR_OFFER_ID',
    'NO_CONFIGURATION_FOR_SALES_CAMPAIGN',
  ];
  public static async requestFormData() {
    await router.isReady();

    if (!store.getters.parameters.wa && !store.getters.parameters.offerId) {
      console.log('Parameters still not set, setting them now');
      await SetupService.setParameters();
    }
    const parameters = store.getters.parameters;

    // const parameters = store.state.parameters;
    const product = parameters.offerId ? parameters.offerId : parameters.wa;
    const tenant = store.state.tenant;

    return new Promise((resolve, reject) => {
      const queryParameter = store.state.parameters.offerId ? 'oid' : 'wa';
      const orderConnector = ConnectorFactory.getOrderConnector();
      // orderAPI
      //   .get(`order/initialFormData?${queryParameter}=` + product, {
      //     headers: {
      //       'osc-tenant': tenant,
      //     },
      //   })
      orderConnector
        .getInitialFormData(queryParameter, product, tenant)
        .then(async (response) => {
          // Don't proceed any further, if user is locked!
          if (response.gpLockedOrArchived) {
            resolve(FormDataParsingResult.GP_LOCKED);
          }

          // check, if the user has an existing subscription and the sales campaign has preventOrderWithExistingSubscription:
          if (
            response.existingSubscription &&
            response.salesCampaign.preventOrderWithExistingSubscription
          ) {
            resolve(FormDataParsingResult.ORDER_EXISTS);
            // check, if the user has an existing subscription and the sales campaign allows no order despite subscription and user did not overrule by button on FollowUpOffer:
          } else if (
            response.existingSubscription &&
            !response.salesCampaign.allowOrderDespiteExistingSubscription &&
            !store.state.followUpOfferConfirmedByUser
          ) {
            resolve(FormDataParsingResult.FOLLOW_UP_OFFER);
            // if user is classified as schnorrer and the sales campaign does not allow orders for so-called schnorrers:
          } else if (
            response.schnorrer &&
            !response.salesCampaign.allowSchnorren
          ) {
            resolve(FormDataParsingResult.TRIAL_EXPIRED);
          }

          //write FormData into store from response
          if (response.salesCampaign !== null) {
            store.commit('changeFormData', response);
            store.commit(
              'setUseDummyAddressForOrder',
              response.orderWithoutAddress,
            );
          }

          //Set default- and response- address and resolve only,
          // if there is no existing subscription (and this is not overwritten
          // by followUpOfferConfirmedByUser) and no Schnorrer
          if (
            (store.state.followUpOfferConfirmedByUser ||
              !response.existingSubscription ||
              (response.existingSubscription &&
                response.salesCampaign !== null &&
                response.salesCampaign
                  .allowOrderDespiteExistingSubscription)) &&
            (!response.schnorrer ||
              (response.schnorrer && response.salesCampaign?.allowSchnorren))
          ) {
            store.commit('changeFormDataToken', response.token);
            store.commit(
              'changeRegistrationMandatory',
              response.registrationMandatory,
            );
            resolve(FormDataParsingResult.SUCCESS);
          }
          reject(new Error(FormDataParsingResult.FAILURE));
          // reject('UndefinedResponse');
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            console.log(error.response.data);
            if (
              this.unavailableProductMessages.includes(
                error.response.data.message,
              )
            ) {
              router.push({
                name: 'ErrorPage',
                params: { type: 'productunavailable' },
              });
            } else {
              router.push({
                name: 'ErrorPage',
                params: { type: 'server' },
              });
            }
          } else {
            console.log('Error:', error);
            if (router.currentRoute.value.name !== 'Error') {
              router.push({
                name: 'ErrorPage',
                params: { type: 'server' },
              });
            }
          }
        });
    });
  }
  public static validateAddress(
    address: FormAddresses,
  ): Promise<addressValidationResponseFromAPI> {
    const orderConnector = ConnectorFactory.getOrderConnector();
    return orderConnector.checkAddresses(
      address,
      store.state.formData.salesCampaign.salesCampaignId,
      store.state.formDataToken,
    );

    // return new Promise((resolve, reject) => {
    // const postData = {
    //   ...address,
    //   salesCampaignId: store.state.formData.salesCampaign.salesCampaignId,
    // };
    // const orderConnector = ConnectorFactory.getOrderConnector();
    // orderConnector
    //   .checkAddresses(
    //     address,
    //     store.state.formData.salesCampaign.salesCampaignId,
    //     store.state.formDataToken,
    //   )
    //
    // orderAPI
    //   .post('order/checkAddresses', postData, {
    //     headers: { token: JSON.stringify(store.state.formDataToken) },
    //   })
    //   .then((response) => {
    //     resolve(response.data);
    //   })
    // .catch((error) => {
    //   console.log(error);
    //   router.push({
    //     name: 'ErrorPage',
    //     params: { type: 'server' },
    //   });
    //   reject();
    // });
    // });
  }

  public static async getCountries() {
    const parameters = store.state.parameters;
    const product = parameters.offerId ? parameters.offerId : parameters.wa;
    const queryParameter = store.state.parameters.offerId ? 'oid' : 'wa';
    const tenant = store.state.tenant;

    // const response = await orderAPI.get(
    //   `order/initialFormData?${queryParameter}=` + product,
    //   {
    //     headers: {
    //       'osc-tenant': tenant,
    //       'token': JSON.stringify(store.state.formDataToken),
    //     },
    //   },
    // );
    // return response.data.countries;
    const orderConnector = ConnectorFactory.getOrderConnector();
    // TODO: Evaluate, if we can use a slightly smaller call to get the countries!
    const response = await orderConnector.getInitialFormData(
      queryParameter,
      product,
      tenant,
    );
    return response.countries;
  }

  public static async prepareCountryOptions() {
    let formCountries = store.state.formData.countries;

    if (Object.keys(formCountries).length === 0) {
      formCountries = await this.getCountries();
    }
    const countryOptions = [];

    for (const [key, value] of Object.entries(formCountries)) {
      countryOptions.push({ value: key, text: value as string });
    }

    // case sensitively sort countryOptions according to text (not key):
    countryOptions.sort(function (a, b) {
      return a.text.localeCompare(b.text);
    });

    store.commit('changeCountryOptions', countryOptions);
  }

  // public static isRequiredInputFilled(
  //   inputValue: string,
  //   requiredLength: number,
  // ) {
  //   return inputValue && inputValue.length >= requiredLength;
  // }
}
