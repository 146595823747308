import { OrderConnectorInterface } from '@/services/connectors/interfaces';
import {
  addressValidationResponseFromAPI,
  CreateOrderResponse,
  FormAddresses,
  InitialFormDataResponseData,
  OrderData,
  PriceResult,
  PriceResultRequestData,
  PriceWithDeliveryDate,
  PriceWithDeliveryDateRequestData,
} from '@/models/OrderData';
import { orderAPI } from '@/services/http-service';
import router from '@/router';
import { FormDataToken } from '@/models/BaseTypes';

export default class OrderConnector implements OrderConnectorInterface {
  getInitialFormData(
    queryParameter: 'oid' | 'wa',
    product: string,
    tenant: string,
  ): Promise<InitialFormDataResponseData> {
    return orderAPI
      .get(`order/initialFormData?${queryParameter}=` + product, {
        headers: {
          'osc-tenant': tenant,
        },
      })
      .then((response) => {
        return response.data;
      });
    // .catch((error) => {
    //   if (error.response && error.response.data) {
    //     console.log(error.response.data);
    //     if (
    //       this.unavailableProductMessages.includes(
    //         error.response.data.message,
    //         //TODO: temporary fix: remove "'"router.currentRoute.name !== 'Error'" as soon as redundant FormService.requestFormData(); call has been removed from Start_old.vue
    //         //else we get console error: "NavigationDuplicated: Avoided redundant navigation to...." on Error page (refactoring ticket: OSC3- 892)
    //       ) &&
    //       router.currentRoute.value.name !== 'Error'
    //     ) {
    //       router.push({
    //         name: 'ErrorPage',
    //         params: { type: 'productunavailable' },
    //       });
    //     } else if (router.currentRoute.value.name !== 'Error') {
    //       //TODO: temporary fix: remove "'"router.currentRoute.name !== 'Error'" as soon as redundant FormService.requestFormData(); call has been removed from Start_old.vue
    //       //else we get console error: "NavigationDuplicated: Avoided redundant navigation to...." on Error page (refactoring ticket: OSC3- 892)
    //       router.push({
    //         name: 'ErrorPage',
    //         params: { type: 'server' },
    //       });
    //     }
    //   } else {
    //     console.log('Error:', error);
    //     if (router.currentRoute.value.name !== 'Error') {
    //       router.push({
    //         name: 'ErrorPage',
    //         params: { type: 'server' },
    //       });
    //     }
    //   }
    //   Promise.reject();
    // });
  }

  checkAddresses(
    address: FormAddresses,
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
  ): Promise<addressValidationResponseFromAPI> {
    const postData = {
      ...address,
      salesCampaignId,
    };

    return orderAPI
      .post('order/checkAddresses', postData, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }

  getPriceWithDeliveryDate(
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
    data: PriceWithDeliveryDateRequestData,
  ): Promise<PriceWithDeliveryDate> {
    return orderAPI
      .post(`/priceWithDeliveryDate/${salesCampaignId}`, data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }

  getPriceForDate(
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
    data: PriceResultRequestData,
  ): Promise<PriceResult> {
    return orderAPI
      .post(`/priceForDate/${salesCampaignId}`, data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }

  createOrder(
    formDataToken: FormDataToken | null,
    data: OrderData,
  ): Promise<CreateOrderResponse> {
    return orderAPI
      .post('order/create', data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }
}
