import { SingleUserOrderBonus } from '@/types/OrderBonusesTypes';
import { AddressTypeLiteral } from '@/types/OrderFormTypes';
import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';

export type priceInfo = {
  description: string;
  price: string;
};

export interface PaymentData {
  paymentMethod: string;
  truncatedCardPan: string;
  ccIns: string;
  ccName: string;
  ccId: string;
  validUntil: string;
  pseudoCardPan: string;
  displayCCName: string;
  iban: string;
  swift: string;
  bankDataId: string;
  bankName: string;
  bankAccountOwner: string;
  bankCountryCode: string;
  creditCardPayment: boolean;
  payPalCode?: string;
}

export interface FormDataSalesCampaign {
  salesCampaignId: string;
  cancellationInfo: string;
  priceForTracking: number;
  priceNetForTracking: number;
  printProductType: string;
  infoPoints: string[];
  priceInfos: priceInfo[];
  name: string;
  recurring: boolean;
  withCosts: boolean;
  registrationMandatory: boolean;
  mailAdvertEnabled: boolean;
  telAdvertEnabled: boolean;
  emailMandatory: boolean;
  allowOrderDespiteExistingSubscription: boolean;
  numberOfBonuses: number;
  bonuses?: SingleUserOrderBonus[];
  noResultAbroad: boolean;
  showStartDate: boolean;
  showGPTypeSelection: boolean;
  showDeliveryAddressCompany: boolean;
  showInvoiceAddressCompany: boolean;
  subscriptionEndDate: string | null;
  subscriptionIssueEndNumber: string | null;
  subscriptionIssueStartNumber: string | null;
  subscriptionStartDate: string | null;
  studentSubscription: boolean;
  giftSubscription: boolean;
  lwl: boolean;
  timeLimitSelectable: boolean;
  specialOffer: boolean;
  preventOrderWithExistingSubscription: boolean;
}

export interface FormData {
  addressSubmittedSuccessfully: boolean;
  gpNr: string;
  gpLockedOrArchived: boolean;
  addresses: FormAddresses;
  isPostOfficeBoxAddress: boolean;
  orderWithoutAddress: boolean;
  registrationMandatory: boolean;
  existingSubscription: boolean;
  schnorrer: boolean;
  offerId: string;
  salesCampaign: FormDataSalesCampaign;
  gotoCheckData?: boolean;
  paymentData?: PaymentData[];
  paymentMethodKeys: string[];
  regionSelectionOptions?: {
    [key: string]: string;
  };
  countries: {
    [key: string]: string;
  };
  trackingData: {
    categoryName: string;
  };
  email: string;
  sapConnectionFailed: boolean;
  startDate: string;
  mailAdvertEnabled: boolean;
  telAdvertEnabled: boolean;
  mmNumber: string;
  pva: string;
}

export type FormAddresses = {
  businessAddress: FormBusinessAddress | null;
  deliveryAddress: FormDeviatingAddress | null;
  invoiceAddress: FormDeviatingAddress | null;
  privateAddress: formAddress | null;
};

export type formAddress = {
  addressType: AddressTypeLiteral;
  addressNumber: string;
  businessAddress: boolean;
  salutation: string;
  title: string;
  firstName: string;
  lastName: string;
  nameEditable: boolean;
  email: string;
  street: string;
  streetNumber: string;
  postOfficeBox: string;
  postOfficeBoxZipCode: string;
  postOfficeBoxCity: string;
  zipCode: string;
  city: string;
  additionalInfo: string;
  room: string;
  floor: string;
  addressAddition: string;
  country: string;
  district: string;
  phone: string;
  businessPhone: string;
  mobilePhone: string;
  privateAddress?: boolean;
  anredeNeutral?: boolean;
};

export type FormBusinessAddress = {
  addressType: AddressTypeLiteral;
  salutation: string;
  companyName: string;
  street: string;
  streetNumber: string;
  postOfficeBox: string;
  postOfficeBoxZipCode: string;
  postOfficeBoxCity: string;
  zipCode: string;
  city: string;
  country: string;
  email: string;
  phone: string;
  businessPhone: string;
  mobilePhone: string;
  companySuffix1: string;
  companySuffix2: string;
  companySuffix3: string;
  mailDeliverySelected: boolean;
  additionalInfo: string;
  floor: string;
  majorCustomerZipCode: string;
  majorCustomerCity: string;
  nameEditable: boolean;
  addressNumber: string;
  district: string;
};

export type FormDeviatingAddress = {
  addressType: AddressTypeLiteral;
  addressNumber: string;
  country: string;
  district: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  companySuffix1: string;
  companySuffix2: string;
  companySuffix3: string;
  mailDeliverySelected: boolean;
  additionalInfo: string;
  floor: string;
  majorCustomerZipCode: string;
  majorCustomerCity: string;
  nameEditable: boolean;
  salutation: string;
  email: string;
  phone: string;
  businessPhone: string;
  mobilePhone: string;
  postOfficeBox: string;
  postOfficeBoxZipCode: string;
  postOfficeBoxCity: string;
};

export enum AddressSubType {
  PRIVATE_ADDRESS,
  BUSINESS_ADDRESS,
  DELIVERY_ADDRESS,
  INVOICE_ADDRESS,
}

export type AddressValidationCheckResult = {
  address: formAddress | FormBusinessAddress | FormDeviatingAddress | null;
  fullAddressNeeded: boolean;
  adrKorOptions: null | Record<
    string,
    { street: string; city: string; district: string; zipCode: string }
  >;
  // adrKorOptions: {
  //   additionalProp1: {
  //     city: string;
  //     district: string;
  //     street: string;
  //     zipCode: string;
  //   };
  //   additionalProp2: {
  //     city: string;
  //     district: string;
  //     street: string;
  //     zipCode: string;
  //   };
  //   additionalProp3: {
  //     city: string;
  //     district: string;
  //     street: string;
  //     zipCode: string;
  //   };
  // } | null;
  cityCorrected: boolean;
  cityError: boolean;
  corrected: boolean;
  districtCorrected: boolean;
  hasErrors: boolean;
  plzOrtKorOptions: null | Record<string, string>;
  // plzOrtKorOptions: {
  //   additionalProp1: string;
  //   additionalProp2: string;
  //   additionalProp3: string;
  // } | null;
  strKorOptions: null | Record<string, string>;
  // strKorOptions: {
  //   additionalProp1: string;
  //   additionalProp2: string;
  //   additionalProp3: string;
  // } | null;
  streetCorrected: boolean;
  streetError: boolean;
  streetNumberError: boolean;
  zipCodeCorrected: boolean;
  zipCodeError: boolean;
  postOfficeBoxError: boolean;
  postOfficeBoxCorrected: boolean;
  postOfficeBoxZipCodeCorrected: boolean;
  postOfficeBoxCityCorrected: boolean;
};

// export type addressValidationResponse = {
//   privateAddressCheckResult: AddressValidationCheckResult;
//   businessAddressCheckResult: AddressValidationCheckResult;
//   deliveryAddressCheckResult: AddressValidationCheckResult;
//   invoiceAddressCheckResult: AddressValidationCheckResult;
// } | null;
export type addressValidationResponseFromAPI = {
  privateAddressCheckResult: AddressValidationCheckResult;
  businessAddressCheckResult: AddressValidationCheckResult;
  deliveryAddressCheckResult: AddressValidationCheckResult;
  invoiceAddressCheckResult: AddressValidationCheckResult;
  subTitleOutLink: string;
  subTitleInfoTextParagraphs: string[];
};

export type AddressCorrectionOptions = {
  addressCorrectionOptions: {
    id: number;
    city: string;
    street: string;
    zipCode: string;
  }[];
  cityCorrectionOptions: {
    value: number;
    text: string;
  }[];
  streetCorrectionOptions: {
    value: number;
    text: string;
  }[];
};

export type InternalSingleAddressValidationResult = {
  streetCorrected: boolean;
  zipCodeCorrected: boolean;
  cityCorrected: boolean;
  postOfficeBoxCorrected: boolean;
  postOfficeBoxCityCorrected: boolean;
  postOfficeBoxZipCodeCorrected: boolean;
  hasErrors: boolean;
  postOfficeBoxError: boolean;
  cityError: boolean;
  streetError: boolean;
  streetNumberError: boolean;
  zipCodeError: boolean;
  corrected: boolean;
  addressCorrectionOptions: {
    id: number;
    city: string;
    street: string;
    zipCode: string;
  }[];
  cityCorrectionOptions: {
    value: number;
    text: string;
  }[];
  streetCorrectionOptions: {
    value: number;
    text: string;
  }[];
};

export type AddressValidationResult = {
  privateAddress: InternalSingleAddressValidationResult;
  businessAddress: InternalSingleAddressValidationResult;
  deliveryAddress: InternalSingleAddressValidationResult;
  invoiceAddress: InternalSingleAddressValidationResult;
};

export type addressCheckResult = {
  address: {
    additionalInfo: string;
    city: string;
    country: string;
    district: string;
    email: string;
    firstName: string;
    lastName: string;
    nameEditable: boolean;
    salutation: string;
    street: string;
    streetNumber: string;
    title: string;
    zipCode: string;
    postOfficeBox: string;
    postOfficeBoxZipCode: string;
    postOfficeBoxCity: string;
  };
  fullAddressNeeded: boolean;
  adrKorOptions: {
    [key: string]: {
      city: string;
      district: string;
      street: string;
      zipCode: string;
    };
  };
  plzOrtKorOptions: {
    [key: string]: string;
  };
  strKorOptions: {
    [key: string]: string;
  };
  addressCorrectionOptions?: {
    id: number;
    city: string;
    street: string;
    zipCode: string;
  }[];
  cityCorrected: boolean;
  cityError: boolean;
  corrected: boolean;
  districtCorrected: boolean;
  hasErrors: boolean;
  cityCorrectionOptions?: { text: string; value: string }[];
  streetCorrectionOptions?: { text: string; value: string }[];
  streetCorrected: boolean;
  streetError: boolean;
  streetNumberError: boolean;
  zipCodeCorrected: boolean;
  zipCodeError: boolean;
  postOfficeBoxError: boolean;
  postOfficeBoxCorrected: boolean;
  postOfficeBoxZipCodeCorrected: boolean;
  postOfficeBoxCityCorrected: boolean;
};

export interface SelectOption {
  value: string | number | null;
  text: string;
  disabled?: boolean;
}

export interface AddressSuggestion {
  id: number;
  city: string;
  street: string;
  zipCode: string;
}

export interface OrderData {
  salesCampaignId: string;
  advertisingMedium: string;
  dummyAddress: boolean;
  pva: string;
  paymentMethodKey: string;
  purchaserAddresses?: FormAddresses;
  recipientAddresses?: FormAddresses;
  agentAddresses?: FormAddresses;
  purchaserPaymentData: PaymentData;
  agentPaymentData?: PaymentData;
  paymentUniqueId: string;
  mailAdvertEnabled: boolean;
  telAdvertEnabled: boolean;
  bonusMatNrs?: string[];
  mmNumber?: string;
  contactNumber: string;
  crmCampaign: string;
  validFrom: string;
  studentEnrollmentCertificateUploadId?: string;
  loggedInCustomerRole?: LoggedInCustomerRole;
  timeLimit?: number;
  recipientPaymentData?: PaymentData;
  trackingId: string;
}

export interface CreateOrderResponse {
  temporary: boolean;
  registrationPossible: boolean;
  validFrom: string | null;
  orderNumbers: string[];
  gpNr: string;
  lastName: string;
  temporaryOrderId: string;
}

export type DeliveryDateInfo = {
  earliestDate: string;
  invalidDates: string[] | null;
};

export type PriceResult = {
  annualPrice: number;
  noResultAbroad: boolean;
  price: number;
  priceGui: string;
};

export type PriceWithDeliveryDate = {
  deliveryDateInfo: DeliveryDateInfo;
  priceResult: PriceResult;
  releaseCalendar: { releaseDate: string; issueNumber: string }[] | null;
};

export type StudentEnrollmentUpload = {
  fileName: string;
  fileId: string;
};

export enum LoggedInCustomerRole {
  PURCHASER = 'PURCHASER',
  RECIPIENT = 'RECIPIENT',
  AGENT = 'AGENT',
}

export type PriceWithDeliveryDateRequestData = {
  mailDelivery: boolean;
  // FormData Addresses always is RECEIVER
  addresses: FormAddresses;
  billingFrequency: string;
  paymentMethodKey: string;
  loggedInCustomerRole: LoggedInCustomerRole;
  timeLimit?: number;
};

export type PriceResultRequestData = {
  // FormData Addresses always is RECEIVER
  addresses: FormAddresses;
  billingFrequency: string;
  paymentMethodKey: string;
  loggedInCustomerRole: LoggedInCustomerRole;
  startDate: string; // YYYY-MM-DD
  timeLimit?: number;
};

type InitialFormDataSalesCampaign = {
  salesCampaignId: string;
  name: string;
  infoPoints: string[];
  priceInfos: {
    description: string;
    price: string;
  }[];
  cancellationInfo: string;
  priceForTracking: number;
  priceNetForTracking: number;
  recurring: boolean;
  printProductType: PrintProductTypeLiteral;
  withCosts: boolean;
  registrationMandatory: boolean;
  noResultAbroad: boolean;
  telAdvertEnabled: boolean;
  mailAdvertEnabled: boolean;
  emailMandatory: boolean;
  showStartDate: boolean;
  showGPTypeSelection: boolean;
  showDeliveryAddressCompany: boolean;
  showInvoiceAddressCompany: boolean;
  allowOrderDespiteExistingSubscription: boolean;
  preventOrderWithExistingSubscription: boolean;
  studentSubscription: boolean;
  giftSubscription: boolean;
  timeLimitSelectable: boolean;
  lwl: boolean;
  numberOfBonuses: number;
  bonuses: string[];
  subscriptionStartDate: string | null;
  subscriptionEndDate: string | null;
  subscriptionIssueStartNumber: string | null;
  subscriptionIssueEndNumber: string | null;
  specialOffer: boolean;
  allowSchnorren?: boolean;
};

export type InitialFormDataResponseData = {
  // addressSubmittedSuccessfully: boolean;
  gpNr: string | null;
  gpLockedOrArchived: boolean;
  addresses: FormAddresses | null;
  // isPostOfficeBoxAddress: boolean;
  orderWithoutAddress: boolean;
  registrationMandatory: boolean;
  existingSubscription: boolean;
  schnorrer: boolean;
  offerId: string | null;
  salesCampaign: InitialFormDataSalesCampaign;
  gotoCheckData?: boolean;
  paymentData?: PaymentData[] | null;
  paymentMethodKeys: string[];
  bonuses?: SingleUserOrderBonus[];
  regionSelectionOptions?: {
    [key: string]: string;
  };
  countries: {
    [key: string]: string;
  };
  trackingData: {
    categoryName: string;
  };
  email: string | null;
  sapConnectionFailed: boolean;
  // startDate: string;
  // mailAdvertEnabled: boolean;
  // telAdvertEnabled: boolean;
  mmNumber: string;
  // pva: string;
  token?: {
    content: {
      random: string;
      expirationTimestamp: number;
    };
    signature: string;
  };
};
