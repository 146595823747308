import store from '@/store';
import router from '@/router';
import {
  LoggedInCustomerRole,
  OrderData,
  PaymentData,
  PriceResult,
  PriceResultRequestData,
  PriceWithDeliveryDate,
  PriceWithDeliveryDateRequestData,
} from '@/models/OrderData';
import flatten from 'flat';
import LZUTF8 from 'lzutf8';
import { SingleUserOrderBonus } from '@/types/OrderBonusesTypes';
import {
  getAddressesOfPurchaser,
  getFirstNameLastNameFromAddresses,
  getUserRole,
} from '@/composables/general-functions';
import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';
import PaymentMethodLiteral from '@/utils/paymentMethodLiteral';

export default class OrderService {
  public static isDayPass(): boolean {
    const dayPassPrefixes = store.getters.content.config.dayPassPrefixes;
    const salesCampaign = store.getters.formData.salesCampaign.salesCampaignId;

    // If day passes are preconfigured for tenant:
    if (dayPassPrefixes.length > 0) {
      // check if at least one prefix is contained in current salesCampaign
      return dayPassPrefixes.some((dayPassPrefix: string) =>
        salesCampaign.includes(dayPassPrefix),
      );
    } else {
      // If there are no day passes preconfigured for tenant:
      return false;
    }
  }
  public static orderWithoutAddress(): boolean {
    return store.state.formData.orderWithoutAddress;
  }

  public static isFreeSubscription(): boolean {
    return !store.state.formData.salesCampaign.withCosts;
  }

  public static createOrderData(): OrderData {
    const state = store.state;
    const formData = state.formData;
    let pva = '';

    if (
      store.getters.formData.regionSelectionOptions &&
      Object.keys(store.getters.formData.regionSelectionOptions).length > 0 &&
      // store.getters.content.config.region &&
      state.formData.salesCampaign.printProductType ===
        PrintProductTypeLiteral.DIGITAL &&
      state.formData.pva !== '-'
    ) {
      pva = state.formData.pva;
    }

    const paymentData: PaymentData = {
      ...state.purchaserPaymentData,
      validUntil:
        state.purchaserPaymentData.validUntil === null
          ? ''
          : state.purchaserPaymentData.validUntil,
    };

    const data: OrderData = {
      advertisingMedium: state.parameters.advertisingMedium,
      contactNumber: state.parameters.ktnr,
      crmCampaign: state.parameters.campaign,
      dummyAddress: store.getters.useDummyAddressForOrder,
      paymentMethodKey: state.purchaserPaymentData.paymentMethod,
      purchaserPaymentData: paymentData,
      pva: pva,
      paymentUniqueId: state.paymentUniqueId,
      salesCampaignId: state.formData.salesCampaign.salesCampaignId,
      validFrom: formData.startDate,
      mailAdvertEnabled: formData.mailAdvertEnabled,
      telAdvertEnabled: formData.telAdvertEnabled,
      studentEnrollmentCertificateUploadId:
        store.state.studentEnrollmentUpload.fileId,
      trackingId: store.state.parameters.pianoTrackingId,
    };

    if (store.state.orderBonusCart.length > 0) {
      data.bonusMatNrs = store.state.orderBonusCart.map(
        (orderBonus: SingleUserOrderBonus) => orderBonus.bonus.materialNumber,
      );
    }

    if (store.state.formData.mmNumber) {
      data.mmNumber = store.state.formData.mmNumber;
    }

    if (store.state.formData.salesCampaign.giftSubscription) {
      data.recipientAddresses = store.state.formData.addresses;
      data.purchaserAddresses = store.state.recruiterAddresses;
      data.loggedInCustomerRole = store.state.userIsReceiver
        ? LoggedInCustomerRole.RECIPIENT
        : LoggedInCustomerRole.PURCHASER;

      if (store.state.formData.salesCampaign.timeLimitSelectable) {
        data.timeLimit = store.state.recruiterGiftSubscriptionLimit;
      }
    } else if (store.state.formData.salesCampaign.lwl) {
      data.purchaserAddresses = formData.addresses;
      data.agentAddresses = store.state.recruiterAddresses;
      data.loggedInCustomerRole = store.state.userIsReceiver
        ? LoggedInCustomerRole.PURCHASER
        : LoggedInCustomerRole.AGENT;

      if (store.state.ibanDataCashBonus.iban !== '') {
        const { firstName, lastName } = getFirstNameLastNameFromAddresses(
          store.state.recruiterAddresses,
        );
        const displayName =
          firstName !== '' ? `${firstName} ${lastName}` : lastName;

        data.agentPaymentData = {
          bankAccountOwner: displayName,
          bankCountryCode: store.state.ibanDataCashBonus.bankCountryCode,
          bankDataId: '',
          bankName: store.state.ibanDataCashBonus.bankName,
          ccId: '',
          ccIns: '',
          ccName: '',
          creditCardPayment: false,
          displayCCName: '',
          iban:
            store.state.ibanDataCashBonus.bankCountryCode +
            store.state.ibanDataCashBonus.iban,
          paymentMethod: PaymentMethodLiteral.SEPA,
          pseudoCardPan: '',
          swift: store.state.ibanDataCashBonus.swift,
          truncatedCardPan: '',
          validUntil: '',
        };
      }
    } else {
      data.purchaserAddresses = formData.addresses;
    }

    return data;
  }

  public static createPaymentData(): PaymentData {
    const state = store.state;
    return {
      ...state.purchaserPaymentData,
      validUntil:
        state.purchaserPaymentData.validUntil === null
          ? ''
          : state.purchaserPaymentData.validUntil,
    };
  }

  public static async persistState() {
    // remove countries for smaller size
    store.commit('changeCountryOptions', {});
    store.commit('changeCountries', {});

    window.sessionStorage.setItem(
      'oscDataToken',
      JSON.stringify(store.state.formDataToken),
    );

    const flatState = flatten(store.state);
    const encodedState = LZUTF8.compress(JSON.stringify(flatState), {
      outputEncoding: 'Base64',
    });

    // await orderAPI.post('/storedData', encodedState, {
    //   headers: { token: JSON.stringify(store.state.formDataToken) },
    // });
    const dataStoreConnector = ConnectorFactory.getDataStoreConnector();
    await dataStoreConnector.storeData(encodedState, store.state.formDataToken);
  }

  public static async restoreState() {
    // For correct tokens in headers for anonymous users after returning from external page the correct token is needed:
    // 1. Get DataToken from localStorage and parse it
    const localStorageDataTokenJSON =
      window.sessionStorage.getItem('oscDataToken');
    let localStorageDataToken;
    if (localStorageDataTokenJSON) {
      localStorageDataToken = JSON.parse(localStorageDataTokenJSON);
    }

    // 2. If DataToken from LocalStorage exists, use this one, else take it from the store:
    const dataToken =
      localStorageDataToken && Object.keys(localStorageDataToken).length
        ? localStorageDataToken
        : store.state.formDataToken;

    // 3. Write token to Store
    store.commit('changeFormDataToken', dataToken);

    const dataStoreConnector = ConnectorFactory.getDataStoreConnector();

    return new Promise<void>((resolve, reject) => {
      //   orderAPI
      //     .get('/storedData', {
      //       headers: { token: JSON.stringify(dataToken) },
      //     })
      dataStoreConnector
        .retrieveData(dataToken)
        .then(async (response) => {
          const decodedState = decodeURIComponent(response);
          const sanitizedState = decodedState
            .replace(/(\r\n|\n|\r)/gm, '')
            .replace(/={1,2}$/, '')
            .trim();

          const flatState = JSON.parse(
            // decode should only happen once!
            // decodeURIComponent(
            LZUTF8.decompress(sanitizedState, {
              inputEncoding: 'Base64',
            }),
            // ),
          );

          const state = flatten.unflatten(flatState);
          store.commit('changeState', state);
          store.commit('changeIsFormValid', true);

          // 4. DataToken now can be deleted safely from SessionStorage
          window.sessionStorage.removeItem('oscDataToken');

          resolve();
        })
        .catch((error) => {
          console.log(error);
          // 4. DataToken now can be deleted safely from SessionStorage
          window.sessionStorage.removeItem('oscDataToken');
          reject();
        });
    });
  }

  public static checkIban(iban: string, swift: string) {
    const paymentConnector = ConnectorFactory.getPaymentConnector();
    return paymentConnector.checkIbanSwift(iban, swift);
  }

  public static checkIbanSwift(iban: string, swift: string, country: string) {
    const paymentConnector = ConnectorFactory.getPaymentConnector();
    return new Promise((resolve, reject) => {
      //   orderAPI
      //     .post(
      //       'order/checkIbanSwift',
      //       {
      //         iban: iban,
      //         swift: swift,
      //       },
      //       { headers: { token: JSON.stringify(store.state.formDataToken) } },
      //     )
      paymentConnector
        .checkIbanSwift(iban, swift)
        .then((response) => {
          if (response.data.checkFailed || response.data.ibanError) {
            resolve('fail');
          } else {
            const data = response.data;
            store.commit(
              'changeAddressNeedsCompletion',
              response.data.knownBySAP,
            );

            const addresses = getAddressesOfPurchaser();

            const { firstName, lastName } =
              getFirstNameLastNameFromAddresses(addresses);
            const displayName =
              firstName !== '' ? `${firstName} ${lastName}` : lastName;

            const purchaserPaymentDataWithSepa = Object.assign(
              {},
              store.state.purchaserPaymentData,
              {
                paymentMethod: PaymentMethodLiteral.SEPA,
                iban: iban,
                swift: swift,
                bankName: data.bankName,
                bankAccountOwner: displayName,
                bankCountryCode: country,
              },
            );

            store.commit(
              'changePurchaserPaymentData',
              purchaserPaymentDataWithSepa,
            );
            resolve('success');
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          router.push({
            name: 'ErrorPage',
            params: { type: 'server' },
          });
          reject();
        });
    });
  }
  public static createOrder(orderData: OrderData) {
    return new Promise((resolve, reject) => {
      const orderConnector = ConnectorFactory.getOrderConnector();
      orderConnector
        .createOrder(store.state.formDataToken, orderData)
        // orderAPI
        //   .post('order/create', orderData, {
        //     headers: { token: JSON.stringify(store.state.formDataToken) },
        //   })
        .then((response) => {
          // TODO: Is it possible that the array contains more than one orderNumber? If so: Would this still be ok for transactionId?
          store.commit('changeTransactionId', response.orderNumbers.toString());
          store.commit('changeCreateOrderResponse', response);
          resolve('success');
        })
        .catch((error) => {
          console.log(error.response.data);
          reject();
        });
    });
  }

  public static getPriceWithDeliveryDate(): Promise<PriceWithDeliveryDate> {
    const paymentMethod = store.state.purchaserPaymentData.paymentMethod;
    const billingFrequency = store.state.purchaserPaymentData.billingFrequency
      ? store.state.purchaserPaymentData.billingFrequency
      : '';

    const loggedInCustomerRole = getUserRole();

    let mailDelivery = false;

    if (store.getters.formData.addresses.deliveryAddress !== null) {
      mailDelivery =
        store.getters.formData.addresses.deliveryAddress.mailDeliverySelected;
    } else if (store.getters.formData.addresses.businessAddress !== null) {
      mailDelivery =
        store.getters.formData.addresses.businessAddress.mailDeliverySelected;
    }

    const data: PriceWithDeliveryDateRequestData = {
      mailDelivery: mailDelivery,
      // FormData Addresses always is RECEIVER
      addresses: store.getters.formData.addresses,
      billingFrequency: billingFrequency,
      paymentMethodKey: paymentMethod,
      loggedInCustomerRole: loggedInCustomerRole,
    };

    if (
      store.state.formData.salesCampaign.giftSubscription &&
      store.state.formData.salesCampaign.timeLimitSelectable
    ) {
      data.timeLimit = store.state.recruiterGiftSubscriptionLimit;
    }

    const orderConnector = ConnectorFactory.getOrderConnector();
    return orderConnector.getPriceWithDeliveryDate(
      store.getters.formData.salesCampaign.salesCampaignId,
      store.state.formDataToken,
      data,
    );

    // return orderAPI
    //   .post(
    //     `/priceWithDeliveryDate/${store.getters.formData.salesCampaign.salesCampaignId}`,
    //     data,
    //     { headers: { token: JSON.stringify(store.state.formDataToken) } },
    //   )
    //   .then((res) => {
    //     return res.data;
    //   });
  }

  public static getPriceResult(): Promise<PriceResult> {
    const paymentMethod = store.state.purchaserPaymentData.paymentMethod;
    const billingFrequency = store.state.purchaserPaymentData.billingFrequency
      ? store.state.purchaserPaymentData.billingFrequency
      : '';

    const loggedInCustomerRole = getUserRole();

    const data: PriceResultRequestData = {
      // FormData Addresses always is RECEIVER
      addresses: store.getters.formData.addresses,
      billingFrequency: billingFrequency,
      paymentMethodKey: paymentMethod,
      loggedInCustomerRole: loggedInCustomerRole,
      startDate: store.getters.formData.startDate,
    };

    if (
      store.state.formData.salesCampaign.giftSubscription &&
      store.state.formData.salesCampaign.timeLimitSelectable
    ) {
      data.timeLimit = store.state.recruiterGiftSubscriptionLimit;
    }

    const orderConnector = ConnectorFactory.getOrderConnector();
    return orderConnector.getPriceForDate(
      store.getters.formData.salesCampaign.salesCampaignId,
      store.state.formDataToken,
      data,
    );

    // return orderAPI
    //   .post(
    //     `/priceForDate/${store.getters.formData.salesCampaign.salesCampaignId}`,
    //     data,
    //     { headers: { token: JSON.stringify(store.state.formDataToken) } },
    //   )
    //   .then((res) => {
    //     return res.data;
    //   });
  }
}
