import createPersistedState from 'vuex-persistedstate';
import { createStore, ModuleTree } from 'vuex';
import * as Cookies from 'js-cookie';
import { Sepa } from '@/models/Sepa';
import { FormAddresses, SelectOption } from '@/models/OrderData';
import * as content from '@/data/content';
import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';
import PaymentPaypal from '@/services/paypal-service';
import CardAboService from '@/services/card-abo-service';
import CardOneOffPayService from '@/services/card-oneoffpay-service';
import OrderService from '@/services/order-service';
import router from '@/router';
import { DateTime } from 'luxon';

import configModule from '@/store/modules/config/index';
import adminOrderBonusesModule from '@/store/modules/adminOrderBonuses';
import { AppRootState } from '@/store/interfaces';
import {
  OrderBonusClassification,
  SingleUserOrderBonus,
} from '@/types/OrderBonusesTypes';
import { getMainAddressFromAddresses } from '@/composables/general-functions';
import PaymentService from '@/services/payment-service';
import PaymentMethodLiteral from '@/utils/paymentMethodLiteral';
import { BillingFrequencyGroupOption } from '@/models/SalesCampaigns';
import { regionOptions } from '@/utils/selectOptions';
import { StudentOrderService } from '@/services/student-order-service';
import {
  ImageUploadResponse,
  ImageUploadResponseMessage,
} from '@/types/ImageUploadTypes';
import { UserRole } from '@/types/UserTypes';
import { AddressTypeLiteral } from '@/types/OrderFormTypes';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';

// Register Modules here
const modules: ModuleTree<AppRootState> = {
  configModule,
  adminOrderBonusesModule,
};

enum Tenants {
  'sz',
  'stz',
  'stn',
  'kwz',
  'maz',
  'lkz',
  'ez',
  'cz',
  'kbb',
  'swb',
  'lz',
}

// Vue.use(Vuex);

export const store = createStore({
  modules,
  state: {
    parameters: {
      wa: '',
      offerId: '',
      advertisingMedium: '',
      campaign: '',
      ktnr: '',
      sourceUrl: '',
      pianoTrackingId: '',
      authScreen: 'default',
    },
    isAddressRequired: false,
    studentOrderFormWasShown: false,
    userIsReceiver: true,
    userRoleSelected: false,
    recruiterAddresses: {
      privateAddress: null,
      businessAddress: null,
      deliveryAddress: null,
      invoiceAddress: null,
    },
    recruiterAddressesSaved: false,
    recruiterGiftSubscriptionLimit: 0,
    isFormValid: false,
    loginChecked: false,
    isLoggedIn: false,
    billingFrequencySelected: false,
    paymentMethodSelected: false,
    tenant: '',
    orderProgress: 1,
    orderCreated: false,
    paymentType: '',
    paymentTruncated: '',
    paymentUniqueId: '',
    cardType: '',
    advertisingConsent: false,
    payFrameUrl: '',
    payFrameInfo: '',
    ibanDataCashBonus: {
      iban: '',
      swift: '',
      bankCountryCode: 'DE',
      bankName: '',
    },
    sepa: {
      iban: '',
      swift: '',
    },
    formData: {
      addressSubmittedSuccessfully: false,
      gpNr: '',
      gpLockedOrArchived: false,
      addresses: {
        businessAddress: null,
        deliveryAddress: null,
        invoiceAddress: null,
        privateAddress: null,
      },
      isPostOfficeBoxAddress: false,
      orderWithoutAddress: false,
      registrationMandatory: false,
      salesCampaign: {
        salesCampaignId: '',
        cancellationInfo: '',
        priceForTracking: 0,
        priceNetForTracking: 0,
        printProductType: '',
        infoPoints: [''],
        name: '',
        priceInfos: [
          {
            description: '',
            price: '',
          },
        ],
        recurring: false,
        withCosts: false,
        registrationMandatory: true,
        mailAdvertEnabled: false,
        telAdvertEnabled: false,
        emailMandatory: false,
        allowOrderDespiteExistingSubscription: false,
        noResultAbroad: false,
        showStartDate: false,
        numberOfBonuses: 0,
        showGPTypeSelection: false,
        showDeliveryAddressCompany: false,
        showInvoiceAddressCompany: false,
        studentSubscription: false,
        subscriptionEndDate: null,
        subscriptionIssueEndNumber: null,
        subscriptionIssueStartNumber: null,
        subscriptionStartDate: null,
        giftSubscription: false,
        lwl: false,
        timeLimitSelectable: false,
        specialOffer: false,
        preventOrderWithExistingSubscription: false,
      },
      pva: '',
      paymentMethodKeys: [''],
      countries: {},
      trackingData: {
        categoryName: '',
      },
      existingSubscription: false,
      offerId: '',
      schnorrer: false,
      email: '',
      startDate: '',
      sapConnectionFailed: true,
      mailAdvertEnabled: false,
      telAdvertEnabled: false,
      mmNumber: '',
    },
    orderBonusCart: [],
    deliveryDateInfo: {
      earliestDate: '',
      invalidDates: [],
      releaseCalendar: [],
    },
    priceResultFetchSuccessful: false,
    priceResult: {
      annualPrice: 0.0,
      noResultAbroad: false,
      price: 0.0,
      priceGui: '',
    },
    formDataToken: null,
    countryOptions: [{ text: 'Deutschland', value: 'DE' }],
    orderBonusCartConfirmed: false,
    purchaserPaymentData: {
      paymentMethod: '',
      truncatedCardPan: '',
      ccIns: '',
      ccName: '',
      ccId: '',
      validUntil: '',
      pseudoCardPan: '',
      displayCCName: '',
      iban: '',
      swift: '',
      bankDataId: '',
      bankName: '',
      bankAccountOwner: '',
      bankCountryCode: '',
      creditCardPayment: false,
      payPalCode: '',
      billingFrequency: '',
    },
    studentEnrollmentUpload: {
      fileName: '',
      fileId: '',
    },
    transactionId: '',
    createOrderResponse: {
      temporary: false,
      registrationPossible: false,
      validFrom: '',
      orderNumbers: [],
      gpNr: '',
      lastName: '',
      temporaryOrderId: '',
    },
    paymentPrefillChecked: false,
    addressNeedsCompletion: false,
    isAddressComplete: false,
    needsFullAddress: false,
    creditCardTimeInvalid: false,
    creditCardDeclined: false,
    userRole: '',
    adminData: {
      logoutUrl: '',
      lastName: '',
      firstName: '',
      email: '',
      homePageInfos: {
        version: '',
        documentationLink: '',
      },
      _links: {
        ADMIN_ADMINS: {},
        ADMIN_USERS: {},
        ADMIN_TENANT_ADMINS: {},
        ADMIN_APPROVED_SALES_CAMPAIGNS: {},
        CONFIGURE_SALES_CAMPAIGNS: {},
      },
    },
    usersList: {
      _embedded: {
        users: [
          {
            login: '',
            _links: {},
          },
        ],
      },
      _links: {},
    },
    salesCampaignsListForAdmin: {
      _embedded: {
        availableSalesCampaigns: [
          {
            active: false,
            approved: false,
            id: '',
            name: '',
            validFrom: '',
            validUntil: '',
          },
        ],
      },
    },
    salesCampaignsListForTenant: [
      {
        active: false,
        approved: false,
        id: '',
        name: '',
        validFrom: '',
        validUntil: '',
        links: [],
      },
    ],
    singleSalesCampaign: {
      id: '',
      name: '',
      tenantId: '',
      offerInfo: '',
      active: false,
      infoPoints: [],
      priceInfos: [
        {
          description: 'Zeitraum',
          price: '',
        },
      ],
      baseInfo: {
        noSchnorren: true,
        paymentRhythm: '',
        printProduct: '',
        printProductType: '',
        text: '',
        validFrom: '',
        validUntil: '',
        withCosts: true,
      },
      priceGui: '',
      bonuses: [
        {
          bonus: {
            materialNumber: '',
            label: '',
            title: '',
            active: false,
            validFrom: '',
            cashBonus: false,
            additionalCharges: [],
            textLabel: '',
            text: '',
            linkLabel: '',
            link: '',
            infoPoints: [],
            imagePath: '',
            classification: OrderBonusClassification.NON_MONETARY,
          },
          fixedBonus: false,
          validUntil: '',
        },
      ],
      allowOrderDespiteExistingSubscription: false,
      allowSchnorren: false,
      countries: null,
      countryGroup: '',
      delayedCharging: 0,
      emailMandatory: false,
      giftSubscription: false,
      lwl: false,
      mailAdvertEnabled: false,
      numberOfBonuses: 0,
      orderCsv: false,
      orderWithoutAddress: false,
      paymentMethods: [],
      billingFrequencyGroup: null,
      showGPTypeSelection: false,
      showInvoiceAddressCompany: false,
      showDeliveryAddressCompany: false,
      showStartDate: false,
      showTimePeriod: false,
      studentSubscription: false,
      subscriptionEndDate: null,
      subscriptionIssueEndNumber: null,
      subscriptionIssueStartNumber: null,
      subscriptionStartDate: null,
      telAdvertEnabled: false,
      timeLimitSelectable: false,
      validFrom: '',
      validUntil: '',
      preventOrderWithExistingSubscription: false,
    },
    selectedOrderBonuses: [],
    salesCampaignFormIsValid: true,
    countryGroups: {},
    billingFrequencyGroups: [],
    followUpOfferConfirmedByUser: false,
    useDummyAddressForOrder: false,
  },
  getters: {
    adminLogoutUrl: (state) => {
      return state.adminData.logoutUrl;
    },
    adminSalesCampaignInfo: (state) => {
      return state.singleSalesCampaign;
    },
    countryLabel: (state) => {
      let value = 'none';
      if (state.formData.addresses.privateAddress !== null) {
        value = state.formData.addresses.privateAddress.country;
      } else if (state.formData.addresses.businessAddress !== null) {
        value = state.formData.addresses.businessAddress.country;
      }
      return state.countryOptions.find(function (country: SelectOption) {
        if (country.value == value) return country.text;
      });
    },
    countryOptions: (state) => {
      return state.countryOptions;
    },
    creditCardDeclined: (state) => {
      return state.creditCardDeclined;
    },
    deliveryDateInfo: (state) => {
      return state.deliveryDateInfo;
    },
    formData: (state) => {
      return state.formData;
    },
    formAddress: (state) => {
      return getMainAddressFromAddresses(state.formData.addresses);
    },
    content: (state) => {
      type Tenant = keyof typeof content;

      function convertToTenant(value: string): Tenant {
        if (value in Tenants) {
          return value as Tenant;
        } else {
          throw new Error('Unsupported Tenant');
        }
      }

      const tenant = convertToTenant(state.tenant);
      return content[tenant];
    },
    isAddressComplete: (state) => {
      return state.isAddressComplete;
    },
    isAddressRequired: (state) => {
      // TODO: ADD Setter!
      return state.isAddressRequired;
    },
    isLoggedIn: (state) => {
      return state.isLoggedIn;
    },
    isPostOfficeBoxAddress: (state) => {
      return state.formData.isPostOfficeBoxAddress;
    },
    isPrintSubscription: (state) => {
      return [
        PrintProductTypeLiteral.PRINT as string,
        PrintProductTypeLiteral.MAGAZINE as string,
      ].includes(state.formData.salesCampaign.printProductType);
    },
    noResultAbroad: (state) => {
      return state.priceResult.noResultAbroad;
    },
    paymentTruncated: (state) => {
      return state.paymentTruncated;
    },
    paymentType: (state) => {
      return state.paymentType;
    },
    priceResult: (state) => {
      return state.priceResult;
    },
    parameters: (state) => {
      return state.parameters;
    },
    purchaserPaymentData: (state) => {
      return state.purchaserPaymentData;
    },
    salesCampaign: (state) => {
      return state.formData.salesCampaign;
    },
    startDate: (state) => {
      return state.formData.startDate;
    },
    createOrderResponse: (state) => {
      return state.createOrderResponse;
    },
    useDummyAddressForOrder: (state) => {
      return state.useDummyAddressForOrder;
    },
  },
  mutations: {
    changeIsLoggedIn(state, value: boolean) {
      state.isLoggedIn = value;
    },
    changeLoginChecked(state, value: boolean) {
      state.loginChecked = value;
    },
    changePayFrameUrl(state, url: string) {
      state.payFrameUrl = url;
    },
    changePayFrameInfo(state, info: string) {
      state.payFrameInfo = info;
    },
    changeOrderProgress(state, step: number) {
      state.orderProgress = step;
    },
    changeTenant(state, tenant: string) {
      state.tenant = tenant;
    },
    changeFormData(state, data) {
      data.pva = '';
      if (
        data.addresses === null ||
        (data.addresses.privateAddress === null &&
          data.addresses.businessAddress === null)
      ) {
        data.addresses = {
          privateAddress: null,
          businessAddress: null,
          deliveryAddress: null,
          invoiceAddress: null,
        };
        if (
          data.salesCampaign.printProductType ===
          PrintProductTypeLiteral.DIGITAL
        ) {
          // data.pva = 'EG';
          data.pva =
            data.regionSelectionOptions && data.regionSelectionOptions['-']
              ? '-'
              : 'EG';
        }
      } else {
        if (
          data.salesCampaign.printProductType ===
          PrintProductTypeLiteral.DIGITAL
        ) {
          if (data.addresses.privateAddress !== null) {
            data.pva = regionOptions.some(
              (regionOption) =>
                data.addresses.privateAddress.disctrict === regionOption.value,
            )
              ? data.addresses.privateAddress.disctrict
              : data.regionSelectionOptions && data.regionSelectionOptions['-']
                ? '-'
                : 'EG';
          } else if (data.addresses.businessAddress !== null) {
            data.pva = regionOptions.some(
              (regionOption) =>
                data.addresses.businessAddress.disctrict === regionOption.value,
            )
              ? data.addresses.businessAddress.disctrict
              : data.regionSelectionOptions && data.regionSelectionOptions['-']
                ? '-'
                : 'EG';
          }
        }
      }
      if (!data.mailAdvertEnabled) {
        data.mailAdvertEnabled = false;
      }
      if (!data.telAdvertEnabled) {
        data.telAdvertEnabled = false;
      }
      state.formData = data;
    },
    // TODO: Adjust implemented calls
    changeAddress(state, data: FormAddresses) {
      state.formData.addresses = data;
    },
    changePaymentType(state, data: string) {
      state.paymentType = data;
    },
    changeCardType(state, data: string) {
      state.cardType = data;
    },
    changePaymentTruncated(state, data: string) {
      state.paymentTruncated = data;
    },
    changeAdvertisingConsent(state, value: boolean) {
      state.advertisingConsent = value;
    },
    changeIsFormValid(state, value: boolean) {
      state.isFormValid = value;
    },
    changeSepa(state, value: Sepa) {
      state.sepa = value;
    },
    changeCountryOptions(state, data) {
      state.countryOptions = data;
    },
    changeCountries(state, data) {
      state.formData.countries = data;
    },
    changePurchaserPaymentData(state, data) {
      if (data.billingFrequency === undefined) {
        data.billingFrequency = '';
      }
      state.purchaserPaymentData = data;
    },
    changeOrderCreated(state, value: boolean) {
      state.orderCreated = value;
    },
    changeParameters(state, data) {
      state.parameters = data;
    },
    changePaymentUniqueId(state, data) {
      state.paymentUniqueId = data;
    },
    changeTransactionId(state, data) {
      state.transactionId = data;
    },
    changeOrderWithoutAddress(state, data) {
      state.formData.orderWithoutAddress = data;
    },
    changeSalesCampaign(state, data) {
      state.formData.salesCampaign = data;
    },
    changeCreateOrderResponse(state, data) {
      state.createOrderResponse = data;
    },
    changePaymentPrefillChecked(state, data) {
      state.paymentPrefillChecked = data;
    },
    changeIsAddressComplete(state, data) {
      state.isAddressComplete = data;
    },
    changeChangeGpNr(state, data) {
      state.formData.gpNr = data;
    },
    changeAddressNeedsCompletion(state, data) {
      state.addressNeedsCompletion = data;
    },
    changeNeedsFullAddress(state, data) {
      state.needsFullAddress = data;
    },
    changeState(state, data) {
      Object.assign(state, data);
    },
    changeCreditCardTimeInvalid(state, data) {
      state.creditCardTimeInvalid = data;
    },
    changeCreditCardDeclined(state, data) {
      state.creditCardDeclined = data;
    },
    changeAdminData(state, data) {
      state.adminData = data;
    },
    changeUsersList(state, data) {
      state.usersList = data;
    },
    changeSalesCampaignsListForAdmin(state, data) {
      state.salesCampaignsListForAdmin = data;
    },
    changeSalesCampaignsListForTenant(state, data) {
      state.salesCampaignsListForTenant = data;
    },
    changeUserRole(state, data) {
      state.userRole = data;
    },
    changeCountryGroups(state, data) {
      state.countryGroups = data;
    },
    changeBillingFrequencyGroups(state, data) {
      state.billingFrequencyGroups = data;
    },
    changeSingleSalesCampaign(state, data) {
      state.singleSalesCampaign = data;
    },
    changeSalesCampaignFormIsValid(state, data) {
      state.salesCampaignFormIsValid = data;
    },
    setAdminLogoutUrl(state, data) {
      state.adminData.logoutUrl = data;
    },
    changeRegistrationMandatory(state, data) {
      state.formData.registrationMandatory = data;
    },
    changeFormDataToken(state, data) {
      state.formDataToken = data;
    },
    changeUsersMailAdvertEnabled(state, consent) {
      state.formData.mailAdvertEnabled = consent;
    },
    changeUsersTelAdvertEnabled(state, consent) {
      state.formData.telAdvertEnabled = consent;
    },
    changeFollowUpOfferConfirmedByUser(state, value: boolean) {
      state.followUpOfferConfirmedByUser = value;
    },
    changeSelectedOrderBonuses(state, data) {
      state.selectedOrderBonuses = data;
    },
    setAddressSubmittedSuccessfully(state, data) {
      state.formData = {
        ...state.formData,
        addressSubmittedSuccessfully: data,
      };
    },
    setBillingFrequency(state, data) {
      state.purchaserPaymentData.billingFrequency = data;
    },
    setBillingFrequencyGroups(state, data) {
      state.billingFrequencyGroups = data;
    },
    setBillingFrequencySelected(state, data) {
      state.billingFrequencySelected = data;
    },
    setEarliestDate(state, data) {
      state.deliveryDateInfo.earliestDate = data;
    },
    setPaymentMethodSelected(state, data) {
      state.paymentMethodSelected = data;
    },
    setStartDate(state, data) {
      state.formData.startDate = data;
    },
    setInvalidDates(state, data) {
      state.deliveryDateInfo.invalidDates = data;
    },
    setPriceResultAnnualPrice(state, data) {
      state.priceResult.annualPrice = data;
    },
    setPriceResultNoResultAbroad(state, data) {
      state.priceResult.noResultAbroad = data;
    },
    setPriceResultPrice(state, data) {
      state.priceResult.price = data;
    },
    setPriceResultPriceGui(state, data) {
      state.priceResult.priceGui = data;
    },
    setReleaseCalendar(state, data) {
      state.deliveryDateInfo.releaseCalendar = data;
    },
    setSalesCampaignRecurring(state, data) {
      state.formData.salesCampaign = {
        ...state.formData.salesCampaign,
        recurring: data,
      };
    },
    setOrderBonusCartConfirmed(state, data) {
      state.orderBonusCartConfirmed = data;
    },
    setMilesAndMoreId(state, data) {
      state.formData.mmNumber = data;
    },
    setPva(state, data) {
      state.formData.pva = data;
    },
    setStudentOrderFormWasShown(state, data) {
      state.studentOrderFormWasShown = data;
    },
    setStudentEnrollmentUpload(state, data) {
      state.studentEnrollmentUpload = data;
    },
    setUserIsReceiver(state, data) {
      state.userIsReceiver = data;
    },
    setUserRoleSelected(state, data) {
      state.userRoleSelected = data;
    },
    setRecruiterAddresses(state, data) {
      state.recruiterAddresses = data;
    },
    setFormAddresses(state, data) {
      state.formData.addresses = data;
    },
    setRecruiterAddressesSaved(state, data) {
      state.recruiterAddressesSaved = data;
    },
    setRecruiterGiftSubscriptionLimit(state, data) {
      state.recruiterGiftSubscriptionLimit = data;
    },
    setIbanDataCashBonus(state, data) {
      state.ibanDataCashBonus = data;
    },
    setPriceResultFetchSuccessful(state, data) {
      state.priceResultFetchSuccessful = data;
    },
    setUseDummyAddressForOrder(state, data: boolean) {
      state.useDummyAddressForOrder = data;
    },
  },
  actions: {
    async updateAdminLogoutUrl(context) {
      // const url = await authAPI
      //   .get('logout')
      //   .then((response) => {
      //     return response.data;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      const authConnector = ConnectorFactory.getAuthConnector();
      const url = await authConnector.getLogoutUrl();
      context.commit('setAdminLogoutUrl', url);
    },
    submitOrder(context) {
      return new Promise((resolve, reject) => {
        if (context.getters.isAddressComplete) {
          context.commit('setUseDummyAddressForOrder', false);
        }

        // For print subscriptions only (only print subscriptions have a customizable start date)
        if (
          context.getters.formData.salesCampaign.printProductType !==
            PrintProductTypeLiteral.DIGITAL &&
          context.getters.formData.salesCampaign.showStartDate
        ) {
          // Check if startDate is more than 7 days in the future
          const startDate = context.getters.formData.startDate;
          const oneWeekLimit = DateTime.now().plus({ days: 7 });
          const startDateConverted = DateTime.fromISO(startDate);
          if (oneWeekLimit < startDateConverted) {
            // If so, paying must be as for recurring payments due to CC requirements => adjust flag
            context.commit('setSalesCampaignRecurring', true);
          }
        }

        if (context.getters.paymentType === 'paypal') {
          //Service call will change window.location with response.absUrl => not return here
          PaymentPaypal.startPayPal();
        } else if (context.getters.paymentType === 'card') {
          if (context.getters.salesCampaign.recurring) {
            //Service call will change window.location with response.absUrl => not return here
            CardAboService.startAgreementSCA();
          } else {
            //Service call will change window.location with response.absUrl => not return here
            CardOneOffPayService.startAgreementSCA();
          }
        } else {
          const orderData = OrderService.createOrderData();
          orderData.purchaserPaymentData.creditCardPayment = false;

          OrderService.createOrder(orderData)
            .then((response) => {
              if (response === 'success') {
                context.commit('changeOrderCreated', true);
                router.push({ name: 'SuccessPage' });
                resolve('success');
              }
            })
            .catch(() => {
              router.push({ name: 'ErrorPage', params: { type: 'server' } });
              reject();
            });
        }
      });
    },
    async updatePriceResult(context) {
      if (context.getters.isPrintSubscription) {
        context.commit('setPriceResultFetchSuccessful', false);
        // Uses startDate (from store) set in Verification step for a price result update!
        await OrderService.getPriceResult()
          .then((data) => {
            context.commit('setPriceResultAnnualPrice', data.annualPrice);
            context.commit('setPriceResultNoResultAbroad', data.noResultAbroad);
            context.commit('setPriceResultPrice', data.price);
            context.commit('setPriceResultPriceGui', data.priceGui);
            context.commit('setPriceResultFetchSuccessful', true);
          })
          .catch((error) => {
            console.log(error.response.data);
            // NoResultAbroad by default false
            context.commit('setPriceResultNoResultAbroad', false);
          });
      }
    },
    async updatePriceWithDeliveryDate(context) {
      //start date / price calculation from SAP only relevant for print subscriptions (magazines / print)
      if (context.getters.isPrintSubscription) {
        context.commit('setPriceResultFetchSuccessful', false);
        await OrderService.getPriceWithDeliveryDate()
          .then((data) => {
            context.commit(
              'setEarliestDate',
              data.deliveryDateInfo.earliestDate,
            );
            context.commit('setStartDate', data.deliveryDateInfo.earliestDate);
            context.commit(
              'setInvalidDates',
              data.deliveryDateInfo.invalidDates,
            );
            context.commit(
              'setPriceResultAnnualPrice',
              data.priceResult.annualPrice,
            );
            context.commit(
              'setPriceResultNoResultAbroad',
              data.priceResult.noResultAbroad,
            );
            context.commit('setPriceResultPrice', data.priceResult.price);
            context.commit('setPriceResultPriceGui', data.priceResult.priceGui);
            context.commit('setReleaseCalendar', data.releaseCalendar);
            context.commit('setPriceResultFetchSuccessful', true);
          })
          .catch((error) => {
            console.log(error.response.data);

            // In case of erroneous response default values are to be used
            // Offset predefined per tenant in content file (currently 10 days each)
            const offset =
              context.getters.content.config
                .earliestStartDateOffsetForPrintInDays;
            const dateWithOffset = DateTime.now().plus({ days: offset });

            let earliestDate;
            // Check for sundays (sundays per se not available as start dates)
            if (dateWithOffset.weekday === 7) {
              earliestDate = dateWithOffset.plus({ days: 1 }).toISO();
            } else {
              earliestDate = dateWithOffset.toISO();
            }

            context.commit('setEarliestDate', earliestDate);
            context.commit('setStartDate', earliestDate);
            // NoResultAbroad by default false
            context.commit('setPriceResultNoResultAbroad', false);
          });
      }
    },
    updateStartDate(context, payload) {
      context.commit('setStartDate', payload);
    },
    confirmOrderBonusCart(context) {
      context.commit('setOrderBonusCartConfirmed', true);
    },
    resetOrderBonusCart(context) {
      context.commit('setOrderBonusCartConfirmed', false);
    },
    resetBillingFrequencySelected(context) {
      context.commit('setBillingFrequencySelected', false);
    },
    resetPaymentMethodSelected(context) {
      context.commit('setPaymentMethodSelected', false);
    },
    selectPaymentMethod(context) {
      context.commit('setPaymentMethodSelected', true);
    },
    selectBillingFrequency(context) {
      context.commit('setBillingFrequencySelected', true);
    },
    updateMilesAndMoreId(context, payload) {
      context.commit('setMilesAndMoreId', payload);
    },
    changeBillingFrequency(context, payload) {
      context.commit('setBillingFrequency', payload);
      return context.dispatch('updatePriceWithDeliveryDate');
    },
    changeRecruiterGiftSubscriptionLimit(context, payload) {
      context.commit('setRecruiterGiftSubscriptionLimit', payload);
    },
    loadBillingFrequencies(context) {
      return PaymentService.getBillingFrequencies(
        context.state.formData.salesCampaign.salesCampaignId,
        context.state.purchaserPaymentData
          .paymentMethod as PaymentMethodLiteral,
      ).then((billingFrequencyOptions: BillingFrequencyGroupOption[]) => {
        context.commit('setBillingFrequencyGroups', billingFrequencyOptions);
      });
    },
    resetStudentOrderFormWasShown(context) {
      context.commit('setStudentOrderFormWasShown', false);
    },
    activateStudentOrderFormWasShown(context) {
      context.commit('setStudentOrderFormWasShown', true);
    },
    resetStudentEnrollmentUpload(context) {
      context.commit('setStudentEnrollmentUpload', {
        fileName: '',
        fileId: '',
      });
    },
    uploadStudentEnrollmentCertificate(context, payload: File) {
      return StudentOrderService.uploadStudentEnrollmentCertificate(
        payload,
      ).then((response: ImageUploadResponse) => {
        if (response.message === ImageUploadResponseMessage.SUCCESS) {
          context.commit('setStudentEnrollmentUpload', {
            fileName: payload.name,
            fileId: response.id,
          });
        }
        return response.message;
      });
    },
    changeUserRole(context, payload: UserRole) {
      context.commit(
        'setUserIsReceiver',
        payload === UserRole.USER_IS_RECEIVER,
      );
    },
    resetUserRoleSelected(context) {
      context.commit('setUserRoleSelected', false);
    },
    activateUserRoleSelected(context, payload: UserRole) {
      context.commit(
        'setUserIsReceiver',
        payload === UserRole.USER_IS_RECEIVER,
      );
      context.commit('setUserRoleSelected', true);
      // If logged in user is recruiter:
      if (!context.state.userIsReceiver) {
        const userAddresses = Object.assign(
          {},
          context.state.formData.addresses,
        );
        if (userAddresses.privateAddress) {
          userAddresses.privateAddress.addressType = userAddresses
            .privateAddress.postOfficeBox
            ? AddressTypeLiteral.POST_OFFICE_BOX
            : AddressTypeLiteral.STREET_ADDRESS;
        }
        context.commit('setRecruiterAddresses', userAddresses);
        const recipientAddresses: FormAddresses = {
          privateAddress: null,
          businessAddress: null,
          deliveryAddress: null,
          invoiceAddress: null,
        };
        context.commit('setFormAddresses', recipientAddresses);
      } else {
        // If user is receiver, Miles and More ID never must be used
        context.commit('setMilesAndMoreId', '');
      }

      // Prefill SEPA data, if user is recruiter and has selected a cash bonus
      if (
        context.state.formData.salesCampaign.lwl &&
        !context.state.userIsReceiver &&
        context.state.orderBonusCart.some(
          (selectedBonus: SingleUserOrderBonus) =>
            selectedBonus.bonus.classification ===
            OrderBonusClassification.CASH,
        )
      ) {
        // Check Payment Data for existing SEPA:
        const paymentData = context.state.formData.paymentData;
        const existingSepaData = paymentData?.find(
          (x) => x.paymentMethod === PaymentMethodLiteral.SEPA,
        );
        if (typeof existingSepaData !== 'undefined') {
          context.commit('setIbanDataCashBonus', {
            // Country Code is used separately in IbanCoreData!
            iban: existingSepaData.iban.substring(2),
            swift: existingSepaData.swift,
            bankName: existingSepaData.bankName,
            bankCountryCode: existingSepaData.bankCountryCode,
          });
        }
      }
    },
    resetRecruiterAddressesSaved(context) {
      context.commit('setRecruiterAddressesSaved', false);
    },
    activateRecruiterAddressesSaved(context) {
      context.commit('setRecruiterAddressesSaved', true);
    },
    updateIbanDataCashBonus(context, payload) {
      context.commit('setIbanDataCashBonus', payload);
    },
  },
  plugins: [
    createPersistedState({
      key: 'osc3',
      // persist parameters only
      paths: ['parameters'],
      storage: {
        getItem: (key: string) => Cookies.get(key),
        setItem: (key: string, value: string) =>
          Cookies.set(key, value, { expires: 1, secure: true }),
        removeItem: (key: string) => Cookies.remove(key),
      },
    }),
  ],
});

export default store;
