<template>
  <nav class="navbar">
    <BasicButton
      variant="link"
      @buttonClicked="stepBack"
      additional-button-classes="navbar-back navbar-item color-text-color"
      >Zurück</BasicButton
    >
    <div class="navbar-brand navbar-item">
      <img :src="logoPath" alt="SZ Plus" />
    </div>
    <div class="progressbar-wrapper navbar-item">
      <ProgressBar v-if="!isInternalView" :steps="5" />
    </div>
  </nav>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';
  import ProgressBar from '@/components/ProgressBar.vue';
  import NavService from '@/services/nav-service';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import BasicButton from '@/components/Buttons/BasicButton.vue';

  export default defineComponent({
    name: 'TheHeader',
    components: {
      ProgressBar,
      BasicButton,
    },
    setup() {
      const store = useStore();
      const route = useRoute();
      const content = computed(() => store.getters.content);

      const logoPath = computed(() => {
        const fileName = store.getters.isPrintSubscription
          ? 'logoPrint.png'
          : 'logo.svg';
        return `/${store.state.tenant}/${fileName}`;
      });

      const isInternalView = computed(() => {
        return route.path.includes('/internal');
      });

      const stepBack = function () {
        NavService.handleStepBack();
      };

      return {
        content,
        logoPath,
        isInternalView,
        stepBack,
      };
    },
  });
</script>
