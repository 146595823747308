import store from '@/store';
import md5 from 'md5';
import router from '@/router';
import PaymentMethodLiteral from '@/utils/paymentMethodLiteral';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';
import { BillingFrequencyOption } from '@/types/PaymentTypes';
import { useCookies } from 'vue3-cookies';

export default class PaymentService {
  public static getServiceTicket() {
    return router.currentRoute.value.query.st;
  }

  public static getProcessId(): string {
    const { cookies } = useCookies();
    return cookies.get('processId');
  }

  public static storePayFrameUrl(absPayFrameUrl: string) {
    store.commit('changePayFrameUrl', absPayFrameUrl);
  }

  public static storeProcessId(processId: string) {
    const { cookies } = useCookies();
    cookies.set('processId', processId);
  }

  public static requestPayResult(
    processId: string,
    serviceTicket: string,
    uri: string,
  ) {
    const paymentConnector = ConnectorFactory.getPaymentConnector();
    return (
      paymentConnector
        .getPaymentResult(
          processId,
          serviceTicket,
          uri,
          store.state.formDataToken,
        )
        // return paymentAPI
        //   .get(
        //     uri + '?processId=' + processId + '&serviceTicket=' + serviceTicket,
        //     { headers: { token: JSON.stringify(store.state.formDataToken) } },
        //   )
        //   .then(response => {
        //     return response.data;
        //   })
        .catch((error) => {
          console.log(error.response.data);
          router.push({
            name: 'ErrorPage',
            params: { type: 'server' },
          });
        })
    );
  }

  public static isPurchaserPaymentDataInvalid() {
    return (
      (store.state.paymentType === 'card' &&
        store.state.purchaserPaymentData.pseudoCardPan.length === 0) ||
      (store.state.paymentType === 'sepa' &&
        store.state.purchaserPaymentData.iban.length === 0)
    );
  }

  public static buildPayContextToken(customerId: string, waId: string) {
    return md5('ctxForSPSPaymentCallBack' + customerId + 'oscWAID' + waId);
  }

  public static readPayContextTokenParam() {
    return router.currentRoute.value.query.ctxtoken;
  }

  public static getBillingFrequencies(
    salesCampaignId: string,
    paymentMethod: PaymentMethodLiteral,
  ): Promise<BillingFrequencyOption[]> {
    const paymentConnector = ConnectorFactory.getPaymentConnector();
    return paymentConnector
      .getBillingFrequencies(salesCampaignId, paymentMethod)
      .then((response) => response.data)
      .catch((error) => console.log(error.response.data));
  }
}
