<template>
  <div class="order-start">
    <ThePageContent
      :title="content.start.headline1"
      :isFoldable="isDigitalSalesCampaign && !showStudentOrderView"
    >
      <!-- main component -->
      <template v-slot:main-content-area>
        <div class="forms-container">
          <TheOverlay :isFullPage="false" :show="!formDataLoaded">
            <!-- bonus -->
            <div v-if="showOrderBonusView">
              <OrderBonus
                :form-data="formData"
                :bonuses-loaded="preloadFormData"
                :order-bonus-cart="orderBonusCart"
                :order-bonus-cart-is-full="shoppingCartIsFilledCorrectly"
                @formPrefilled="setFormPrefilled"
                @confirmOrderBonusSelection="confirmOrderBonusSelection"
                @removeItemFromCart="removeSelectedBonusByBonus($event)"
              />
            </div>

            <!-- user role -->
            <div v-else-if="requestUserRoleSelection">
              <OrderUserRoleSelection
                :prefill-form-data="preloadFormData"
                :subscription-type="subscriptionType"
                @formPrefilled="setFormPrefilled"
              />
            </div>

            <!-- recruiter -->
            <div
              v-else-if="
                requestRecruiterAddress && !recruiterFormDependencySolved
              "
            >
              <OrderRecruiter
                :prefill-form-data="preloadFormData"
                :subscription-type="subscriptionType"
                @formPrefilled="setFormPrefilled"
                :request-miles-and-more-id="
                  requestMilesAndMoreId && hasLwlOrGift
                "
                :request-iban="requestIbanForCashBonus"
                :iban-data="ibanDataCashBonus"
                @recruiterAddressesSaved="showOrderForm"
                @updateMilesAndMoreId="updateMilesAndMoreId($event)"
                @updateIban="updateIban($event)"
              />
            </div>

            <!-- digital -->
            <div v-else-if="isDigitalSalesCampaign && !showStudentOrderView">
              <OrderDigital
                @reloadPage="reloadPage"
                @formPrefilled="setFormPrefilled"
                :prefill-form-data="preloadFormData"
                :request-miles-and-more-id="
                  requestMilesAndMoreId && !hasLwlOrGift
                "
                :is-follow-up-form="isMultipleAddressesSubscription"
                @updateMilesAndMoreId="updateMilesAndMoreId($event)"
              />
            </div>

            <!-- print -->
            <div v-else-if="!isDigitalSalesCampaign && !showStudentOrderView">
              <OrderPrint
                @formPrefilled="setFormPrefilled"
                :prefill-form-data="preloadFormData"
                :request-miles-and-more-id="
                  requestMilesAndMoreId && !hasLwlOrGift
                "
                :enable-login-interface="!isMultipleAddressesSubscription"
                :subscription-type="subscriptionType"
                :is-follow-up-form="isMultipleAddressesSubscription"
                @updateMilesAndMoreId="updateMilesAndMoreId($event)"
              />
            </div>

            <!-- student -->
            <div v-else-if="showStudentOrderView">
              <OrderStudents
                :prefill-form-data="preloadFormData"
                @formPrefilled="setFormPrefilled"
              />
            </div>
          </TheOverlay>
        </div>
      </template>

      <!-- sidebar component -->
      <template v-slot:side-content-area>
        <SideArea>
          <div>
            <OrderDetails :hide-order-bonus-cart="!orderBonusSelected" />
          </div>
        </SideArea>

        <SideArea v-if="showOrderBonusView">
          <div>
            <OrderBonusShoppingCartForm
              :order-bonus-selected="orderBonusSelected"
              :selected-order-bonuses="orderBonusCart"
              :order-bonus-cart-is-full="shoppingCartIsFilledCorrectly"
              @removeItemFromCart="removeSelectedBonus($event)"
              @confirmOrderBonusSelection="confirmOrderBonusSelection"
            />
          </div>
        </SideArea>
      </template>
    </ThePageContent>
  </div>
</template>
<script lang="ts">
  import FormService from '@/services/form-service';
  import GtmService from '@/services/gtm-service';
  import SetupService from '@/services/setup-service';
  import AuthService from '@/services/auth-service';
  import {
    formAddress,
    FormBusinessAddress,
    PaymentData,
  } from '@/models/OrderData';
  import PaymentMethodLiteral from '@/utils/paymentMethodLiteral';
  import OrderDigital from '@/views/neubestellung/abo/orderViews/Order_digital.vue';
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch,
    nextTick,
  } from 'vue';
  import { getOrderFormLogic } from '@/composables/order-form';
  import OrderDetails from '@/components/OrderDetails.vue';
  import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';
  import OrderPrint from '@/views/neubestellung/abo/orderViews/Order_print.vue';
  import OrderBonus from '@/views/neubestellung/abo/orderViews/OrderBonus.vue';
  import OrderBonusShoppingCartForm from '@/components/Forms/OrderBonusShoppingCartForm.vue';
  import {
    OrderBonusClassification,
    SingleUserOrderBonus,
  } from '@/types/OrderBonusesTypes';
  import OrderStudents from '@/views/neubestellung/abo/orderViews/OrderStudents.vue';
  import OrderUserRoleSelection from '@/views/neubestellung/abo/orderViews/OrderUserRoleSelection.vue';
  import OrderRecruiter from '@/views/neubestellung/abo/orderViews/OrderRecruiter.vue';
  import { SubscriptionType } from '@/types/GeneralInterfaceTypes';
  import { IbanCoreData } from '@/types/PaymentTypes';
  import { getUserIsPurchaser } from '@/composables/general-flags';
  import ThePageContent from '@/components/PageLayout/ThePageContent.vue';
  import SideArea from '@/components/PageLayout/SideArea.vue';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { FormDataParsingResult } from '@/types/OrderFormTypes';

  export default defineComponent({
    name: 'OrderPage',
    components: {
      OrderRecruiter,
      OrderUserRoleSelection,
      OrderStudents,
      OrderBonusShoppingCartForm,
      OrderBonus,
      OrderPrint,
      OrderDigital,
      OrderDetails,
      ThePageContent,
      SideArea,
      TheOverlay,
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const formDataLoaded = ref(false);
      const preloadFormData = ref(false);
      const paymentDataWasPrefilled = ref(false);
      const content = computed(() => store.getters.content);
      const checkToSkipToVerification = ref(false);

      const registrationMandatory = computed(() => {
        return store.state.formData.registrationMandatory;
      });

      const setFormPrefilled = function () {
        formDataLoaded.value = false;
        nextTick(() => {
          formDataLoaded.value = true;
        });
      };

      const {
        formData,
        address,
        orderWithoutAddress,
        countryOptions,
        goToCheckData,
      } = getOrderFormLogic(null, null, null);

      const isDigitalSalesCampaign = computed(
        () =>
          formData.value.salesCampaign.printProductType ===
          PrintProductTypeLiteral.DIGITAL,
      );

      // Order Bonus related (used in init logic)
      const orderBonusSelected = computed(
        () => store.state.orderBonusCartConfirmed,
      );

      const orderBonusCart = ref(store.state.orderBonusCart);

      const showOrderBonusView = computed(() => {
        return (
          !orderBonusSelected.value &&
          formData.value.salesCampaign.bonuses !== undefined &&
          formData.value.salesCampaign.bonuses.length > 0
        );
      });

      const hasLwlOrGift = computed(() => {
        return (
          formData.value.salesCampaign.lwl ||
          formData.value.salesCampaign.giftSubscription
        );
      });

      const requestMilesAndMoreId = computed(() =>
        store.state.orderBonusCart.some(
          (selectedBonus: SingleUserOrderBonus) =>
            selectedBonus.bonus.classification ===
            OrderBonusClassification.MILES_AND_MORE,
        ),
      );

      const requestIbanForCashBonus = computed(() =>
        store.state.orderBonusCart.some(
          (selectedBonus: SingleUserOrderBonus) =>
            selectedBonus.bonus.classification ===
            OrderBonusClassification.CASH,
        ),
      );

      // INIT Order.vue Logic

      const skipToVerification = async function () {
        store.commit('changeIsFormValid', true);
        store.commit('changeIsAddressComplete', true);
        await store.dispatch('updatePriceWithDeliveryDate').then(() => {
          router.push({ name: 'VerificationPage' });
        });
      };

      const checkExistingSepa = function (paymentData: PaymentData[]) {
        const prefill = paymentData.find(
          (x) => x.paymentMethod === PaymentMethodLiteral.SEPA,
        );

        if (typeof prefill !== 'undefined') {
          store.commit('changePurchaserPaymentData', prefill);
          store.commit('changeSepa', {
            iban: prefill.iban,
            sepa: prefill.swift,
          });
          store.commit('changePaymentType', 'sepa');
          paymentDataWasPrefilled.value = true;
        }
      };

      const checkExistingCard = function (paymentData: PaymentData[]) {
        const prefill = paymentData.find(
          (x) => x.paymentMethod === PaymentMethodLiteral.CREDIT_CARD,
        );

        if (typeof prefill !== 'undefined') {
          store.commit('changePurchaserPaymentData', prefill);
          store.commit('changePaymentTruncated', prefill.truncatedCardPan);
          store.commit('changePaymentType', 'card');
          paymentDataWasPrefilled.value = true;
        }
      };

      const checkExistingPayPal = function (paymentData: PaymentData[]) {
        const prefill = paymentData.find(
          (x) => x.paymentMethod === PaymentMethodLiteral.PAYPAL,
        );

        if (typeof prefill !== 'undefined') {
          store.commit('changePurchaserPaymentData', prefill);
          store.commit('changePaymentType', 'paypal');
          paymentDataWasPrefilled.value = true;
        }
      };

      const checkExistingInvoice = function (paymentData: PaymentData[]) {
        const prefill = paymentData.find(
          (x) => x.paymentMethod === PaymentMethodLiteral.INVOICE,
        );

        if (typeof prefill !== 'undefined') {
          store.commit('changePurchaserPaymentData', prefill);
          store.commit('changePaymentType', 'invoice');
          paymentDataWasPrefilled.value = true;
        }
      };

      // checkExistingPaymentData:
      // * checks paymentData from initialFormData call for existing paymentData and
      // * prefills paymentData in purchaserPaymentData
      const checkExistingPaymentData = function () {
        const paymentData = formData.value.paymentData;
        // check only existing paymentData, if
        // 1. paymentData was received
        // 2. prefill not already checked
        // 3. in case of gift subscriptions: user is not receiver!
        // 4. in case of lwl subscriptions: user is receiver!
        // 5. salesCampaign is not free
        if (
          paymentData &&
          !store.state.paymentPrefillChecked &&
          ((formData.value.salesCampaign.giftSubscription &&
            !store.state.userIsReceiver) ||
            !formData.value.salesCampaign.giftSubscription) &&
          ((formData.value.salesCampaign.lwl && store.state.userIsReceiver) ||
            !formData.value.salesCampaign.lwl) &&
          formData.value.salesCampaign.withCosts
        ) {
          checkExistingSepa(paymentData);
          checkExistingCard(paymentData);
          checkExistingPayPal(paymentData);
          checkExistingInvoice(paymentData);
        }
      };

      const checkAddressIfValid = function (
        address: formAddress | FormBusinessAddress,
      ) {
        const streetAddressIsValid =
          address.street.length > 0 &&
          address.streetNumber.length > 0 &&
          address.zipCode.length > 0 &&
          address.city.length > 0 &&
          countryOptions.value.some(
            (countryOption: { text: string; value: string }) =>
              address.country === countryOption.value,
          );
        const postOfficeBoxAddressIsValid =
          address.postOfficeBox.length > 0 &&
          address.postOfficeBoxCity.length > 0 &&
          address.postOfficeBoxZipCode.length > 0 &&
          countryOptions.value.some(
            (countryOption: { text: string; value: string }) =>
              address.country === countryOption.value,
          );
        return streetAddressIsValid || postOfficeBoxAddressIsValid;
      };

      const preexistingAddressIsValid = computed(() => {
        // Miles & More must be blocking, if Miles&More is to be requested AND currently is empty:
        let milesAndMoreValid = true;
        if (
          formData.value.salesCampaign.bonuses &&
          formData.value.salesCampaign.bonuses.length > 0
        ) {
          milesAndMoreValid =
            requestMilesAndMoreId.value && formData.value.mmNumber.length > 0;
        }

        // If address is NOT required:
        //    1. Miles & More must be valid (either not requested or not empty)
        //    2. Address country must be valid (must be found in countryOptions for SalesCampaign)
        if (orderWithoutAddress.value) {
          return (
            milesAndMoreValid &&
            countryOptions.value.some(
              (countryOption: { text: string; value: string }) =>
                address.value.country === countryOption.value,
            )
          );
        }
        let addressIsValid = false;
        if (formData.value.addresses.privateAddress !== null) {
          addressIsValid = checkAddressIfValid(
            formData.value.addresses.privateAddress,
          );
        }
        if (formData.value.addresses.businessAddress !== null) {
          addressIsValid = checkAddressIfValid(
            formData.value.addresses.businessAddress,
          );
        }

        // If address IS required:
        //    1. Miles & More must be valid (either not requested or not empty)
        //    2. Either street address or p/o box address must be valid
        //        (checked in checkAddressIfValid() above)
        return milesAndMoreValid && addressIsValid;
      });

      // Will show Order subview for student subscriptions as soon as studentOrderFormWasShown is set to true
      const showStudentOrderView = computed(() => {
        return store.state.studentOrderFormWasShown;
      });

      const isMultipleAddressesSubscription = computed(() => {
        return (
          formData.value.salesCampaign.giftSubscription ||
          formData.value.salesCampaign.lwl
        );
      });

      const userRoleSelected = computed(() => {
        return store.state.userRoleSelected;
      });

      const requestUserRoleSelection = computed(() => {
        // Display UserRoleSelection for logged-in users in giftSubscriptions or LwL Campaigns
        //    after orderBonusView was shown and only, if role was not yet selected
        return (
          // TODO: Evaluate, of 1st condition is valid for LwL
          !isDigitalSalesCampaign.value &&
          !showOrderBonusView.value &&
          store.state.isLoggedIn &&
          isMultipleAddressesSubscription.value &&
          !userRoleSelected.value
        );
      });

      const subscriptionType = computed(() => {
        if (formData.value.salesCampaign.giftSubscription) {
          return SubscriptionType.GIFT_SUBSCRIPTION;
        } else if (formData.value.salesCampaign.lwl) {
          return SubscriptionType.LWL_SUBSCRIPTION;
        }
        return SubscriptionType.SINGLE_ADDRESS;
      });

      const requestRecruiterAddress = computed(() => {
        // Display UserRoleSelection for logged-in users in giftSubscriptions or LwL Campaigns
        //    after orderBonusView was shown and only, if role was not yet selected or user is not logged in
        return (
          // TODO: Evaluate, of 1st condition is valid for LwL
          !isDigitalSalesCampaign.value &&
          !showOrderBonusView.value &&
          isMultipleAddressesSubscription.value &&
          (userRoleSelected.value || !store.state.isLoggedIn)
        );
      });

      const recruiterFormDependencySolved = computed(() => {
        if (
          formData.value.salesCampaign.giftSubscription ||
          formData.value.salesCampaign.lwl
        ) {
          return store.state.recruiterAddressesSaved;
        } else {
          return true;
        }
      });

      const trackPageLoad = function () {
        let action = 'Ihre Daten';
        if (showOrderBonusView.value) {
          action = 'Artikelauswahl';
        } else if (requestUserRoleSelection.value) {
          if (subscriptionType.value === SubscriptionType.GIFT_SUBSCRIPTION) {
            action = 'Auswahl Geschenk-Funnel-Typ';
          } else if (
            subscriptionType.value === SubscriptionType.LWL_SUBSCRIPTION
          ) {
            action = 'Auswahl LWL-Funnel-Typ';
          }
        } else if (
          requestRecruiterAddress.value &&
          !recruiterFormDependencySolved.value
        ) {
          if (subscriptionType.value === SubscriptionType.GIFT_SUBSCRIPTION) {
            action = 'Schenkender';
          } else if (
            subscriptionType.value === SubscriptionType.LWL_SUBSCRIPTION
          ) {
            action = 'Daten des Vermittlers';
          }
        } else if (!showStudentOrderView.value) {
          if (subscriptionType.value === SubscriptionType.GIFT_SUBSCRIPTION) {
            action = 'Geschenkempfaenger';
          } else if (
            subscriptionType.value === SubscriptionType.LWL_SUBSCRIPTION
          ) {
            action = 'Daten des Abonnenten';
          }
        }

        GtmService.pushGenericEvent({
          category: 'Checkout',
          action: action,
          label: store.state.formData.salesCampaign.salesCampaignId,
        });
      };

      const processFixedOrderBonuses = function () {
        if (formData.value.salesCampaign.bonuses) {
          // Count fixed bonuses to check later, if all bonuses are fixed
          let fixedBonusesCount = 0;
          for (const orderBonus of formData.value.salesCampaign.bonuses) {
            if (orderBonus.fixedBonus) {
              fixedBonusesCount++;
            }
          }

          // If cart is empty and all SalesCampaign bonuses are fixed bonuses:
          if (
            fixedBonusesCount === formData.value.salesCampaign.bonuses.length &&
            orderBonusCart.value.length === 0
          ) {
            for (const orderBonus of formData.value.salesCampaign.bonuses) {
              orderBonusCart.value.push(orderBonus);
            }
            store.dispatch('confirmOrderBonusCart');
          }
        }
      };

      const initialLoad = async function () {
        formDataLoaded.value = false;
        SetupService.cleanOrderDataCookies();
        store.commit('changeOrderProgress', 2);
        let formDataResult: unknown | string = '';

        if (!store.state.isFormValid) {
          formDataResult = await FormService.requestFormData().catch(
            (error) => {
              console.error('Error in requestFormData', error);
              router.push({
                name: 'ErrorPage',
                params: { type: 'server' },
              });
            },
          );
          await AuthService.checkLogin('', registrationMandatory.value, true);
        }

        if (
          formDataResult === FormDataParsingResult.GP_LOCKED ||
          store.state.formData.gpLockedOrArchived
        ) {
          await router.push({ name: 'GpLocked' });
          return;
        } else if (formDataResult === FormDataParsingResult.ORDER_EXISTS) {
          await router.push({
            name: 'OrderExists',
            params: { type: 'OrderExists' },
          });
          return;
        } else if (formDataResult === FormDataParsingResult.FOLLOW_UP_OFFER) {
          await router.push({
            name: 'FollowUpOffer',
          });
          return;
        } else if (formDataResult === FormDataParsingResult.TRIAL_EXPIRED) {
          await router.push({
            name: 'ErrorPage',
            params: { type: 'trialexpired' },
          });
          return;
        }

        // Skip OrderBonus view, if only fixed bonues are contained
        processFixedOrderBonuses();

        // Check only existing paymentData with paymentData prefill, if:
        // 1. OrderBonusView is not to be shown
        // 2. LWL / GiftScubsription: user Role was selected
        if (
          !showOrderBonusView.value &&
          ((isMultipleAddressesSubscription.value && userRoleSelected.value) ||
            !isMultipleAddressesSubscription.value)
        ) {
          checkExistingPaymentData();
        }

        // If skip to verification is to be done:
        if (
          goToCheckData.value &&
          paymentDataWasPrefilled.value &&
          preexistingAddressIsValid.value &&
          formDataResult === FormDataParsingResult.SUCCESS
        ) {
          await skipToVerification();
        } else {
          // prefill form and prepare country options only, if form is not to be skipped:
          // TODO: Evaluate, why prepareCountryOptions is async!
          await FormService.prepareCountryOptions();
          // Start form preloading in sub views
          preloadFormData.value = true;
        }

        trackPageLoad();
      };

      const reinitializePage = async function () {
        formDataLoaded.value = false;
        store.commit('changeOrderProgress', 2);

        processFixedOrderBonuses();

        if (
          checkToSkipToVerification.value &&
          ((isMultipleAddressesSubscription.value && userRoleSelected.value) ||
            !isMultipleAddressesSubscription.value)
        ) {
          checkExistingPaymentData();
        }

        // If skip to verification is to be done:
        if (
          goToCheckData.value &&
          checkToSkipToVerification.value &&
          paymentDataWasPrefilled.value &&
          preexistingAddressIsValid.value
        ) {
          await skipToVerification();
        } else {
          // Start form preloading in sub views
          preloadFormData.value = true;
        }

        trackPageLoad();
      };

      onMounted(() => {
        if (store.getters.formData.addressSubmittedSuccessfully) {
          reinitializePage();
        } else {
          initialLoad();
        }
      });

      const reloadPage = function () {
        preloadFormData.value = false;
        initialLoad();
      };

      // BONUSES SECTION
      const removeSelectedBonus = function (index: number) {
        if (orderBonusCart.value) {
          const removedBonusArray = orderBonusCart.value.splice(index, 1);
          const removedBonus =
            removedBonusArray.length > 0 ? removedBonusArray[0] : undefined;

          if (removedBonus) {
            GtmService.pushOrderBonusEvent({
              category: 'Checkout',
              action: 'Zugabe entfernt',
              label: store.state.formData.salesCampaign.salesCampaignId,
              orderBonuses: [removedBonus],
            });
          }
        }
      };

      const removeSelectedBonusByBonus = function (
        materialNumberToRemove: string,
      ) {
        const orderBonusToRemove = orderBonusCart.value.find(
          (orderBonus: SingleUserOrderBonus) =>
            orderBonus.bonus.materialNumber === materialNumberToRemove,
        );
        // Proceed only, if Bonus is not fixed!
        if (orderBonusToRemove && !orderBonusToRemove.fixedBonus) {
          const index = orderBonusCart.value.findIndex(
            (orderBonusInCart: SingleUserOrderBonus) => {
              return (
                orderBonusInCart.bonus.materialNumber ===
                orderBonusToRemove.bonus.materialNumber
              );
            },
          );
          if (-1 !== index) {
            removeSelectedBonus(index);
          }
        }
      };

      const fixedBonusesInCart = function () {
        // 1. Get fixed bonuses from SalesCampaign
        const fixedBonuses = formData.value.salesCampaign.bonuses?.filter(
          (orderBonus: SingleUserOrderBonus) => orderBonus.fixedBonus,
        );

        // 2. Check for all fixes Bonuses in Cart
        let allFixedBonusesInCart = true;
        // 2a. Only check, if there are fixed bonuses
        if (fixedBonuses) {
          let index = 0;
          // 2b. As long as allFixed Bonuses are true and index is in range of fixed bonuses array
          while (allFixedBonusesInCart && index < fixedBonuses.length) {
            // 2c. …assert, that fixed bonus as index can be found in cart (else break loop)
            allFixedBonusesInCart = orderBonusCart.value.some(
              (orderBonusInCart: SingleUserOrderBonus) =>
                orderBonusInCart.bonus.materialNumber ===
                fixedBonuses[index].bonus.materialNumber,
            );
            // 2d. Increase index for next item
            index++;
          }
        }
        // 3. Return result
        return allFixedBonusesInCart;
      };

      const shoppingCartIsFilledCorrectly = computed(() => {
        // The order bonuses cart must not contain more or less bonuses than defined in salesCampaign
        return (
          orderBonusCart.value.length ===
          formData.value.salesCampaign.numberOfBonuses
        );
      });

      const updateMilesAndMoreId = function (newMilesAndMoreId: string) {
        formData.value.mmNumber = newMilesAndMoreId ? newMilesAndMoreId : '';
        store.dispatch('updateMilesAndMoreId', newMilesAndMoreId);
      };

      const confirmOrderBonusSelection = function () {
        if (fixedBonusesInCart() && shoppingCartIsFilledCorrectly.value) {
          store.dispatch('confirmOrderBonusCart');
          // reset flag to activate form preload for formerly not present sub-views
          preloadFormData.value = false;
          // set flag to enable skipToVerification in re-init method
          checkToSkipToVerification.value = true;
          // re-init page in next tick (otherwise preloadFormData change would not be detected)
          nextTick(() => {
            reinitializePage();
          });
        }
      };

      // Student Subscriptions
      watch(showStudentOrderView, () => {
        if (!showStudentOrderView.value) {
          // reset flag to activate form preload for formerly not present sub-views
          preloadFormData.value = false;
          // set flag to enable skipToVerification in re-init method
          if (getUserIsPurchaser()) {
            checkToSkipToVerification.value = true;
          }
          // re-init page in next tick (otherwise preloadFormData change would not be detected)
          nextTick(() => {
            reinitializePage();
          });
        }
      });

      // User Role Selection
      watch(userRoleSelected, () => {
        if (userRoleSelected.value) {
          preloadFormData.value = false;
          if (getUserIsPurchaser()) {
            checkToSkipToVerification.value = true;
          }
          nextTick(() => {
            reinitializePage();
          });
        }
      });

      const showOrderForm = function () {
        preloadFormData.value = false;
        if (getUserIsPurchaser()) {
          checkToSkipToVerification.value = true;
        }
        store.dispatch('activateRecruiterAddressesSaved');
        nextTick(() => {
          reinitializePage();
        });
      };

      const updateIban = function (newIbanData: IbanCoreData) {
        store.dispatch('updateIbanDataCashBonus', newIbanData);
      };

      const ibanDataCashBonus = computed(() => store.state.ibanDataCashBonus);

      return {
        preloadFormData,
        formDataLoaded,
        initialLoad,
        content,
        setFormPrefilled,
        formData,
        isDigitalSalesCampaign,
        showOrderBonusView,
        orderBonusSelected,
        removeSelectedBonus,
        confirmOrderBonusSelection,
        orderBonusCart,
        requestMilesAndMoreId,
        hasLwlOrGift,
        updateMilesAndMoreId,
        shoppingCartIsFilledCorrectly,
        removeSelectedBonusByBonus,
        showStudentOrderView,
        reloadPage,
        requestUserRoleSelection,
        requestRecruiterAddress,
        showOrderForm,
        recruiterFormDependencySolved,
        isMultipleAddressesSubscription,
        subscriptionType,
        requestIbanForCashBonus,
        updateIban,
        ibanDataCashBonus,
      };
    },
  });
</script>
